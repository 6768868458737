import otterLogo from '../assets/ray-harrington-crop.webp'

const OtterSpinner = () => (
  <div className="grid-center">
    <img
      className="App-logo"
      alt="ray-harrington-0yaKFO-_GJM-unsplash"
      data-license-url="https://unsplash.com/license"
      data-source-url="https://unsplash.com/photos/a-sea-otter-swimming-in-a-body-of-water-0yaKFO-_GJM"
      src={otterLogo}
      title="Otter playing at Dartmoor Otter Sanctuary - Ray Harrington"
    />
  </div>
)

export default OtterSpinner
